<template>
  <Layout title="歌曲检测完毕">
    <div :class="prefixCls">
      <!--   鉴定结果   -->
      <div :class="prefixCls + '_result'">
        <div
          :class="[prefixCls + '_result__card', prefixCls + '_result__theme1']"
        >
          <h2>背景音乐详情</h2>
          <ul>
            <li>歌手： GlaringLights(B)</li>
            <li>歌手： GlaringLights(B)</li>
            <li>歌手： GlaringLights(B)</li>
            <li>歌手： GlaringLights(B)</li>
            <li>歌手： GlaringLights(B)</li>
            <li>歌手： GlaringLights(B)</li>
          </ul>
        </div>
        <!--   鉴定结果-无   -->
        <div
          :class="[prefixCls + '_result__card', prefixCls + '_result__empty']"
        >
          <h2>暂无检测借给</h2>
          <p>可能原因</p>
          <ul>
            <li>1.识别歌曲不存在曲库，可以联系后台人员添加.</li>
            <li>2.识别文件可能损坏或质量较低，请更改音乐文件.</li>
          </ul>
        </div>

        <!--   音频展示   -->
        <div :class="prefixCls + '_result__bar'" id="waveform"></div>
      </div>
      <div :class="prefixCls + '_footer'">
        <HaiButton @click="$router.push({ path: '/' })">返回首页</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'
// import WaveSurfer from 'wavesurfer.js'

const prefixCls = 'copyright-page'
export default {
  name: 'Copyright',
  components: {
    Layout,
    HaiButton
  },

  data() {
    return {
      prefixCls
    }
  },

  mounted() {
    this.$nextTick(() => {
      // var wavesurfer = WaveSurfer.create({
      //   container: document.querySelector('#waveform'),
      //   waveColor: '#2E60B3',
      //   progressColor: '#fff',
      //   backend: 'MediaElement',
      //   mediaControls: false
      // })
      // wavesurfer.load(require('./Uu - 那女孩对我说 (完整版).mp3'))
    })
  },

  methods: {}
}
</script>

<style lang="scss">
$prefixCls: 'copyright-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  text-align: center;
  &_result {
    padding-top: 280px;
    display: inline-block;
    width: 90%;
    position: relative;
    &__card {
      padding: 20px;
      text-align: left;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      left: 0;
      top: 0;
      color: #68e7ed;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -8px;
        border: 8px transparent solid;
        transform: rotate(-135deg) translate(50%, -50%);
      }
      h2 {
        color: rgba(104, 231, 237, 1);
        opacity: 0.85;
        font-size: 24px;
        margin-bottom: 20px;
      }
      ul {
        font-size: 16px;
        opacity: 0.85;
        line-height: 26px;
      }
    }
    &__theme1 {
      border-top: 1px #fff solid;
      background: #18364e;
      &::before {
        border-left-color: #18364e;
        border-top-color: #18364e;
      }
    }
    &__theme2 {
      border-top: 1px #5391f9 solid;
      background: #101f48;
    }
    &__empty {
      border-top: 1px #fff solid;
      background: #18364e;
      text-align: center;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      h2 {
        margin-bottom: 40px;
      }
      ul,
      p {
        text-align: left;
        line-height: 26px;
      }
    }
    &__bar {
    }
  }
  &_footer {
    margin-top: 130px;
  }
}
</style>
